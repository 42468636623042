import React, { Lazy, Suspense } from 'react';
// import React from 'react';
import ReactDOM from 'react-dom';

// Components path is an alias setting up in webpack.conf as a Resolve
// import ContactForm from 'Components/ContactForm'
// import PortfolioGrid from './PortfolioGrid'
// import ContactForm from './ContactForm'

const LazyPortfolioGrid = React.lazy( () => import( /* webpackPrefetch: true, webpackChunkName: "PortfolioGrid" */ './PortfolioGrid') );

function PortfolioGrid() {
    return (
        <div>
        <Suspense fallback={<div>Loading...</div>}>
        <LazyPortfolioGrid />
        </Suspense>
        </div>
    );
}

ReactDOM.render(<PortfolioGrid />, document.getElementById('portfolio-grid'));


const LazyContactForm = React.lazy( () => import( /* webpackPrefetch: true, webpackChunkName: "ContactForm" */ './ContactForm') );

function ContactForm() {
    return (
        <Suspense fallback={<div>Loading...</div>}>
        <LazyContactForm />
        </Suspense>
    );
}

ReactDOM.render(<ContactForm />, document.getElementById('modal-form'));
